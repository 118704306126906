import React, { useContext, useEffect, useState } from "react"
import { Formik } from "formik"
import * as Yup from "yup"
import { appRoutes } from "../constants/app-routes"
import { useRouter } from "next/router"
import Link from "next/link"

import OnboardingWrapper from "../components/onboarding/onboarding-wrapper"
import { Input } from "../components/ui/inputs"
import { PurpleButton } from "../components/ui/buttons"
import { ErrorField } from "../components/ui/error-fields"
import { errorMessages } from "../constants/error-messages"
import { LightParagraph, MarginParagraph } from "../components/ui/elements"
import { Form } from "../components/ui/forms/login-form"
import { createCookie, handleLogin } from "../utils/auth"
import CustomHead from "../components/head"
import { UserContext } from "../context/user-context"
import SocialAuthSmall from "../components/ui/forms/social-auth-small"
import styled from "styled-components"

const LoginPage = () => {
    const router = useRouter()
    const [errorResponse, setErrorResponse] = useState(false)
    const [successfulLogin, setSuccessfulLogin] = useState(false)
    const { currentUser, fetchUser } = useContext(UserContext)

    useEffect(() => {
        successfulLogin && currentUser && router.push(appRoutes.DASHBOARD.route)
    }, [currentUser, successfulLogin])

    const validationSchema = Yup.object({
        email: Yup.string()
            .email(errorMessages.email.invalid)
            .required(errorMessages.required)
            .typeError(errorMessages.email.typeError),
        password: Yup.string().required(errorMessages.required)
    })

    const handleRegistration = () => {
        router.push(appRoutes.ONBOARDING.REGISTRATION.route)
    }

    const submitHandler = async (values) => {
        try {
            const res = await handleLogin(values)

            if (res.status === 200) {
                createCookie(res.data.data.access_token)
                if (!res.data.data.email_verified) {
                    document.location = appRoutes.ONBOARDING.EMAIL_VERIFICATION.route
                    return
                }
                if (!res.data.data.profile_completed) {
                    document.location = appRoutes.ONBOARDING.YOUR_PROFILE.route
                    return
                }

                fetchUser()
                document.location = appRoutes.DASHBOARD.route
            } else {
                setErrorResponse("Něco nám tu neštymuje – konkrétně e-mail nebo heslo. Zkusíš to znova?")
            }
        } catch (error) {
            console.error(error)
        }
    }

    return (
        <>
            <CustomHead title={appRoutes.LOGIN.name} />
            <OnboardingWrapper>
                <h1>Přihlášení</h1>
                <Subheading>Cesta k tréninkům vede přes vlastní JNV účet. Přihlas se tady:</Subheading>
                <Formik
                    initialValues={{ email: "", password: "" }}
                    validationSchema={validationSchema}
                    onSubmit={(values) => submitHandler(values)}
                >
                    {(props) => (
                        <Form className="text-center" onSubmit={props.handleSubmit}>
                            <Input
                                type="email"
                                name="email"
                                placeholder="e-mail"
                                onChange={props.handleChange}
                                onBlur={props.handleBlur}
                                isDisabled={!props.touched.email}
                                isError={props.touched.email && props.errors.email}
                            />
                            {props.touched.email && props.errors.email && <ErrorField>{props.errors.email}</ErrorField>}
                            <Input
                                type="password"
                                name="password"
                                placeholder="heslo"
                                onChange={props.handleChange}
                                onBlur={props.handleBlur}
                                isDisabled={!props.touched.password}
                                isError={props.touched.password && props.errors.password}
                            />
                            {props.touched.password && props.errors.password && (
                                <ErrorField>{props.errors.password}</ErrorField>
                            )}
                            {errorResponse && errorResponse !== "ok" && <ErrorField>{errorResponse}</ErrorField>}
                            <PurpleButton
                                type="submit"
                                isDisabled={!props.isValid || props.values === props.initialValues}
                            >
                                PŘIHLÁSIT SE
                            </PurpleButton>
                        </Form>
                    )}
                </Formik>
                <MarginParagraph marginTop={10}>
                    Nepamatuješ si heslo? V pořádku.{" "}
                    <Link href={appRoutes.FORGOTTEN_PASSWORD.route}>Tady si ho obnovíš!</Link>
                </MarginParagraph>
                <MarginParagraph marginBottom={10} marginTop={10}>
                    nebo
                </MarginParagraph>
                <SocialAuthSmall setSuccessfulLogin={setSuccessfulLogin} />
                <MarginParagraph margin={10}>Nemáš účet?</MarginParagraph>
                <PurpleButton onClick={handleRegistration}>ZAREGISTROVAT</PurpleButton>
            </OnboardingWrapper>
        </>
    )
}

export default LoginPage

const Subheading = styled(LightParagraph)`
    max-width: 310px;
`
